<template>
  <div class="GlobalTable">
    <div v-if="seachDataList.length > 0" >
      <div class="table" v-for="(item, index) in seachDataList" :key="index">
        <div class="table_header">
          <div class="table_header_left">
            <p class="table_index">{{ index + 1 }}</p>
            <p>
              下单商户：<span>{{ item.createCompanyName }}</span>
            </p>
            <!-- <p>下单店员：{{ item.staffName }}</p> -->
            <p>成交时间：{{ item.ackTime || "--" }}</p>
            <p>参与活动：{{ item.activityName }}</p>
            <p>
              订单状态：{{
                item.state == "00"
                  ? "待确认"
                  : item.state == "01"
                  ? "已确认"
                  : item.state == "02"
                  ? "待支付"
                  : item.state == "03"
                  ? "已绑码"
                  : item.state == "04"
                  ? "已收货"
                  : item.state == "05"
                  ? "降价收货"
                  : item.state == "10"
                  ? "已取消"
                  : item.state == "20"
                  ? "已作废"
                  : "已退回"
              }}
            </p>
          </div>
          <div class="table_header_right">
            <div class="bicycle_lane" v-if="item.subsidyWay=='00'">旧机加价</div>
            <div class="bicycle_lane Star_support" v-else>换新抵扣</div>
          </div>
        </div>
        <div>
          <el-table :data="[item]" border style="width: 100%" id="excelOrder">
            <el-table-column label="回收订单号" align="center">
              <template slot-scope="{ row }">
                <div class="underline" @click="goOrderDetail(row)">
                  {{ row.orderNo }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="下单门店" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.storeName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="下单店员" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.staffName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="回收机型" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.phoneName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="换新顾客信息" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.customerName == '' && row.customerPhone == ''"
                  >--</span
                >
                <span v-else>{{ row.customerName }}</span
                ><span v-if="row.customerPhone">-{{ row.customerPhone }}</span>
              </template>
            </el-table-column>
            <el-table-column label="赠送商品" align="center">
              <template slot-scope="{ row }">
                <div class="underline" @click="noteFn(row,true)">
                  {{ row.giftNum }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="赠送价值" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.giftCost }}</div>
              </template>
            </el-table-column>
            <el-table-column label="换新商品" align="center">
              <template slot-scope="{ row }">
                <div class="underline" @click="noteFn(row,false)">
                  {{ row.productNum }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="换新补贴金额" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.subsidyPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column label="回收价" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.quotePrice }}</div>
              </template>
            </el-table-column>
            <el-table-column label="顾客成交价" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.finalPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column label="顾客成交价（含补贴）" width="140px" align="center">
              <template slot-scope="{ row }">
                <div class="wrap">
                  <span>{{ row.afterSubsidyFinalPrice }}</span>
                  <p style="color: #ff687b"
                  v-if="row.subsidyWay=='00'"
                    >含{{ row.subsidyPrice }}补贴</p
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="回收总毛利" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.allRebateAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column label="员工奖励" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.staffReward }}</div>
              </template>
            </el-table-column>
            <el-table-column label="公司回收净利" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.netRebateAmount }}</div>
              </template>
            </el-table-column>
            <el-table-column label="总压价金额" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.allCutPrice || "0" }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="orderPagina">
        <el-pagination
          background
          :current-page="page.pageNum"
          :page-size="10"
          layout="total, prev, pager, next,jumper"
          :total="page.total"
          class="el-pagination-c"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <div v-else class="emptyOrder">暂无订单~</div>
    <!-- <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="seachDataList"
      :currentPage="page.pageNum"
      :total="page.total"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column label="回收订单号" slot="orderNo" align="center">
        <template slot-scope="{ row }">
          <div class="underline" @click="goOrderDetail(row)">{{ row.orderNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="下单门店" slot="storeName" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.storeName || "--" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="店员" slot="staffName" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.staffName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="回收机型" slot="phoneName" align="center">
        <template slot-scope="{ row }">
          <div>
            <span>{{row.phoneName}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" slot="state" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
        </template>
      </el-table-column>
      <el-table-column label="换新商品" slot="productNum" align="center">
        <template slot-scope="{ row }">
          <div class="underline" @click="noteFn(row)">{{ row.productNum }}</div>
        </template>
      </el-table-column>
      <el-table-column label="补贴后回收价" slot="afterSubsidyQuotePrice" align="center">
        <template slot-scope="{ row }">
          <div class="wrap">
            <span>{{ row.afterSubsidyQuotePrice }}</span>
            <span style="color:#ff687b">含{{ row.subsidyPrice }}补贴</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="顾客成交价" slot="afterSubsidyFinalPrice" align="center">
        <template slot-scope="{ row }">
          <div class="wrap">
            <span>{{ row.afterSubsidyFinalPrice }}</span>
            <span style="color:#ff687b">含{{ row.subsidyPrice }}补贴</span>
          </div>
        </template>
      </el-table-column>
        <el-table-column label="换新顾客信息" slot="customerName" align="center">
        <template slot-scope="{ row }">
            <span>{{ row.customerName || '--'}}</span><span v-if="row.customerPhone">-{{ row.customerPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="补贴结算方式" slot="settlementMethod" align="center">
        <template slot-scope="{ row }">
            <span>{{ row.customerName?"线上结算":"线下结算"}}</span>
            <p style="color: #0981FF;text-decoration: underline;cursor: pointer;">交易账单</p>
        </template>
      </el-table-column>
    </GlobalTable> -->
    <!-- 换新商品列表 -->
    <el-dialog
        title="换新商品列表"
        :visible.sync="noteShow"
        :close-on-click-modal="false"
        width="980px"
    >
      <GlobalTable
          ref="GlobalTable"
          v-loading="noteLoading"
          :columns="noteList"
          :data="noteData"
          :maxHeight="700"
          :isPagination="false"
      >
      <el-table-column
          label="换新补贴金额"
          slot="subsidyPrice"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.isGift?row.sellPrice:row.subsidyPrice }}</span>
            <p v-if="!row.isGift"><el-tag type="danger" size="mini">{{ row.subsidyWay=='00'?'旧机加价':'换新抵扣'}}</el-tag></p>
            <p v-else><el-tag type="danger" size="mini">赠品全额抵扣</el-tag></p>
          </template>
        </el-table-column>
        <el-table-column
          label="购买凭证"
          width="120px"
          slot="voucher"
          align="center"
        >
          <template slot-scope="{ row }">
            <img style="width: 100px;height: 80px;" :src="row.voucher" @click="seeSampleImage(row.voucher)" alt="">
          </template>
        </el-table-column>
      </GlobalTable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="noteShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          imgList = [];
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "Table",
  components: { ElImageViewer },
  data() {
    return {
      imgList: [],
      showViewer: false,
      loading: false,
      noteLoading:false,
      noteShow: false,
      noteList: [
        {label: "商品编码", prop: "productCode"},
        {label: "换新商品", prop: "productName"},
        { label: "实售价", prop: "realSellPrice" },
        // {label: "换新补贴金额", prop: "subsidyPrice"},
        {slotName:"subsidyPrice"},
        {label: "商品销售价", prop: "sellPrice"},
        {label: "商品成本", prop: "cost"},
        {label: "新机串号", prop: "imei"},
        {slotName: "voucher"}, //购买凭证
      ],
      noteData:[],
      seachDataList: [],
      entryTime: null,
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumns: [
        { label: "商家名称", prop: "createCompanyName" },
        { slotName: "orderNo" },//回收订单号
        { slotName: "storeName" },
        { slotName: "staffName" },
        { label: "成交时间", prop: "ackTime" },
        { slotName: "phoneName" },
        { slotName: "state" },
        { label: "参与活动", prop: "activityName" },
        { label: "换新补贴金额", prop: "subsidyPrice" },
        { slotName: "productNum" },//换新商品
        { label: "回收价", prop: "quotePrice" },
        { slotName: "afterSubsidyQuotePrice" },//补贴后回收价
        { slotName: "afterSubsidyFinalPrice" },//顾客成交价
        { label: "回收总毛利", prop: "allRebateAmount" },
        { label: "员工奖励", prop: "staffReward" },
        { label: "公司回收净利", prop: "netRebateAmount" },
        { slotName: "customerName" },//换新顾客信息
        { label: "换新商品售价", prop: "productSellPrice" },
        { label: "换新商品成本", prop: "productCost" },
        { slotName: "settlementMethod" },//补贴结算方式
      ],
    };
  },
  created() {
    let startTime = moment().day(moment().day() - 31).format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    startTime = Date.parse(startTime);
    endTime = Date.parse(endTime);
    this.entryTime = [startTime, endTime];
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 跳转至订单详情
    goOrderDetail(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: {type: "edit", id: row.orderNo},
      });
      window.open(routeData.href, "_blank");
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(1111);
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        companyId:this.SeachParams.companyId,
        inspectorId:this.SeachParams.inspectorId,
        activityId: this.SeachParams.activityId,
        productId: this.SeachParams.productId,
        storeId: this.SeachParams.storeId,
        staffName:this.SeachParams.staffName,
        orderNo:this.SeachParams.orderNo,
        state:this.SeachParams.state,
        customerName:this.SeachParams.customerName,
        customerPhone:this.SeachParams.customerPhone,
        subsidyWay:this.SeachParams.subsidyWay,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api.orderRenewList(baseRequest).then((res) => {
        if (res.code === 1) {
          console.log(367,res);
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
          this.initexpressNo = "";
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 换新商品数量
    noteFn(row,isGift) {
      this.noteShow = true;
      this.noteLoading = true;
      _api.findProducts({orderNo:row.orderNo,isGift}).then(res => {
        if(res.code == 1){
          this.noteData = res.data;
          this.noteLoading = false;   
        }
      })
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    }, 
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {
  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }
  .table {
    margin-top: 14px;

    .table_header {
      width: 100%;
      padding: 11px 0;
      padding-right: 10px;
      border-radius: 4px 4px 0px 0px;
      background: #e9f0fe;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table_header_left {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #4c84ff;
        justify-content: space-between;

        > p {
          margin-right: 30px;
        }

        .table_index {
          width: 30px;
          height: 18px;
          background: #f5b724;
          border-radius: 3px 9px 9px 3px;
          text-align: center;
          line-height: 18px;
          margin-right: 9px;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .table_header_right {
        display: flex;

        .bicycle_lane {
          height: 20px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #21d8c2;
          border-radius: 10px;
          font-size: 12px;
          color: #21d8c2;
          padding: 0 11px;
          line-height: 20px;
        }

        .Star_support {
          margin-left: 10px;
          color: #ff9204;
          border: 1px solid #ffaf29;
        }
      }
    }

    /deep/ .el-table .el-table__header th {
      background: #f9fbfd !important;
      border-width: 0;
      border-bottom-width: 1px;
    }
  }
  .orderPagina {
    margin: 50px 50px 20px 0;
    text-align: right;
  }
  .autoshow::-webkit-scrollbar {
    display: none;
  }

  .emptyOrder {
    width: 100%;
    text-align: center;
    padding: 80px 0px;
    font-size: 24px;
    color: rgb(102, 102, 102);
    background: white;
    font-weight: bold;
  }
  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .ml-10 {
    margin-left: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .font-w {
    font-weight: bold;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .flex-as {
    display: flex;
    align-items: flex-start;
  }
  .wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .underline{
    text-decoration: underline;
    color:#0981ff;
    cursor: pointer;
  }
}
</style>
