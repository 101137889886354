<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleChange="handleChange"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item label="订单号" slot="orderNo" style="margin: 0;" label-width="60px">
        <el-input v-model="orderNo" placeholder="请输入订单号查询" clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="订单状态"
        slot="stateSelect"
        style="margin:0"
      >
        <el-select
          v-model="state"
          placeholder="请选择订单状态"
          clearable 
          multiple 
          filterable
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button
          size="small"
          type="warning"
          icon="el-icon-download"
          :loading="serching"
          @click="importDetailExcel"
          >导出商品明细</el-button
        >
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="isExecl"
          @click="clickExcel"
          >导出Excel</el-button
        >
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "Form",
  data() {
    return {
      isExecl:false,
      serching:false,
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      state:[],
      stateOptions: [
            {
              label: "全部",
              value: "",
            },
            {
              label: "待确认",
              value: "00",
            },
            {
              label: "已确认",
              value: "01",
            },
            {
              label: "待支付",
              value: "02",
            },
            {
              label: "已绑码",
              value: "03",
            },
            {
              label: "已收货",
              value: "04",
            },
            {
              label: "降价收货",
              value: "05",
            },
            {
              label: "已取消",
              value: "10",
            },
            {
              label: "已作废",
              value: "20",
            },
            {
              label: "已退回",
              value: "30",
            },
          ],
      initData: {
        entryTime: [
          moment().day(moment().day() - 30).format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      orderNo:"",
      SeachParams: {},
      formItemList: [
        {
          labelName: "所属商家",
          key: "companyId",
          childrenKey: "storeId",
          childrenKey2: "inspectorId",
          childrenKey3: "activityId",
          childrenKey4: "productId",
          type: "selectFilterable",
          placeholder: "请选择或输入商家查询",
          lableWidth: "78px",
          clear: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "下单门店",
          fatherKey: "companyId",
          key: "storeId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "区域/总监",
          fatherKey: "companyId",
          key: 'inspectorId',
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: '80px',
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false
        },
      {
          labelName: "活动名称",
          fatherKey: "companyId",
          key: "activityId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false,
        },
        {
          labelName: "商品名称",
          fatherKey: "companyId",
          key: "productId",
          type: "selectFilterable",
          placeholder: "请先选择门店商家",
          lableWidth: "78px",
          clear: true,
          disabled: true,
          option: [],
          clearFun: () => false,
        },
        { slotName: "classSelect" },
        {
          key: "staffName",
          type: "input",
          labelName: "下单店员",
          placeholder: "请输入下单店员姓名查询",
        },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "成交时间",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable:true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: "orderNo" },
        { slotName: "stateSelect" },
        {
          key: "customerName",
          isNumber: true,
          type: "input",
          labelName: "顾客姓名",
          placeholder: "请输入顾客姓名查询",
        },
        {
          key: "customerPhone",
          isNumber: true,
          type: "input",
          labelName: "顾客手机号",
          placeholder: "请输入顾客手机号查询",
        },  
        {
          key: "subsidyWay",
          type: "select",
          lableWidth:'80px',
          labelName: "补贴方式",
          placeholder: "请选择",
          option: [
            {
              value: "00",
              label: "旧机加价",
            },
            {
              value: "01",
              label: "换新抵扣",
            },
          ],
        },  
      ],
    };
  },
  created() {
    let companyId = Number(this.$route.query.companyId)
    let activityId = Number(this.$route.query.activityId);
    let orderNo = this.$route.query.orderNo;
    if (activityId) {  
      this.initData.companyId = companyId
      this.initData.activityId = activityId
      this.orderNo = orderNo
      this.initData.entryTime = null
      this.handleChange({key:'companyId'},companyId)
     }
    this.getMerchantSelect()
    this.handleConfirm(this.initData)
  },
  watch: {},
  methods: {
    handleChange(v1, v2) {
      if (v1.key === "companyId") {
        const index = this.formItemList.findIndex((v) => v.key === 'storeId');
        const inspectorIdIndex = this.formItemList.findIndex(v => v.key === 'inspectorId');
        const activityIndex = this.formItemList.findIndex((v) => v.key === 'activityId');
        const productIndex = this.formItemList.findIndex((v) => v.key === 'productId');
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[inspectorIdIndex].disabled = false;
          this.formItemList[activityIndex].disabled = false;
          this.formItemList[productIndex].disabled = false;
          this.getStoreList(v2);
          this.getInspectorId(v2);
          this.activitySelect(v2)
          this.productSelect({companyId: v2,activityId:""});

        } else {
          this.formItemList[index].disabled = true;
          this.formItemList[inspectorIdIndex].disabled = true;
          this.formItemList[activityIndex].disabled = true;
          this.formItemList[productIndex].disabled = true;
        }
      }

    },
    // 所属商家下拉
    async getMerchantSelect(){
      const storeRes = await _api.getSelectList();
      if (storeRes.code === 1) {
        this.formItemList[0].option = storeRes.data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }
    },
    // 请求下单门店下拉
    getStoreList(companyId) {
      _api.getStoreSelectList({ companyId }).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "storeId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.value,
            value: v.key,
          };
        });
      });
    },
    // 区域总监下拉
    getInspectorId(v) {
      _api.inspectorAreaSelect({ companyId: v,scope: "00" }).then(r => {
        const data = r.data || [];
        const index = this.formItemList.findIndex(v => v.key === 'inspectorId');
        this.formItemList[index].option = data.map(v => {
          return {
            value: v.staffId,
            label: (v.staffName ? v.staffName : '') + (v.areaName ? '-' + v.areaName : '')
          }
        })
      })
    },
    // 活动下拉
    activitySelect(companyId){
      _api.activitySelect({companyId}).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "activityId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }) 
    },
    //商品下拉
    productSelect(params){
      console.log('lala',params);
      _api.productSelect(params).then((r) => {
        const data = r.data || [];
        const index = this.formItemList.findIndex((v) => v.key === "productId");
        this.formItemList[index].option = data.map((v) => {
          return {
            label: v.name,
            value: v.id,
          };
        });
      }) 
    },
    handleConfirm(data,cd) {
      console.log(data);
      let startTime = "";
      let endTime = "";
      if (data) {
        if (data.entryTime === null) {
          startTime = "";
          endTime = "";
        } else {
          startTime = moment(data.entryTime[0]).format("x");
          endTime = moment(data.entryTime[1]).format("x");
        }
      } 
      this.SeachParams = {
        companyId: data && data.companyId ? data.companyId : "",
        inspectorId: data && data.inspectorId ? data.inspectorId : "",
        activityId: data && data.activityId ? data.activityId : "",
        productId: data && data.productId ? data.productId : "",
        storeId: data && data.storeId ? data.storeId : "",
        staffName: data && data.staffName ? data.staffName : "",
        orderNo: this.orderNo,
        state: this.state,
        customerName: data && data.customerName ? data.customerName : "",
        customerPhone: data && data.customerPhone ? data.customerPhone : "",
        subsidyWay: data && data.subsidyWay ? data.subsidyWay : "",
        startTime: startTime,
        endTime: endTime,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      console.log(223,this.SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", this.SeachParams);
    },
    importDetailExcel() {
      let data = {
        ...this.SeachParams,
      };
      this.serching=true
      _api.excelOrderRenewproducts(data).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
            }
          } catch (err) {
            let start = "";
            let end = "";
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD");
              end = moment(Number(data.endTime)).format("YYYY.MM.DD");
            } else if (data.dateType == "01") {
              start = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            } else if (data.dateType == "03") {
              start = moment()
                .weekday(1)
                .format("YYYY.MM.DD");
              end = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            } else if (data.dateType == "05") {
              start = moment()
                .startOf("month")
                .format("YYYY.MM.DD");
              end = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            }
            console.log(moment().startOf("month"));
            const fileName = "换新商品明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
        this.serching=false
      }).catch(err=>{
        this.serching=false
      });
    },
    clickExcel() {
      let data = {
        ...this.SeachParams,
      };
      this.isExecl=true
      _api.excelOrderRenew(data).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
            }
          } catch (err) {
            let start = "";
            let end = "";
            if (data.startTime || data.endTime) {
              start = moment(Number(data.startTime)).format("YYYY.MM.DD");
              end = moment(Number(data.endTime)).format("YYYY.MM.DD");
            } else if (data.dateType == "01") {
              start = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            } else if (data.dateType == "03") {
              start = moment()
                .weekday(1)
                .format("YYYY.MM.DD");
              end = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            } else if (data.dateType == "05") {
              start = moment()
                .startOf("month")
                .format("YYYY.MM.DD");
              end = moment()
                .month("YYYY-MM-DD")
                .format("YYYY.MM.DD");
            }
            console.log(moment().startOf("month"));
            // const fileName = `【客户数据】${start || ""}${
            //   end ? "-" + end : ""
            // }.xlsx`;
            const fileName = "换新订单.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
        this.isExecl=false
      }).catch(err=>{
        this.isExecl=false
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
}
</style>
